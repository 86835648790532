import { Header as BaseHeader, Icons, Language as LanguageLib } from '@in/component-library';
import React, { FC, useContext } from 'react';
import { AppContext } from '../context/app-context';
import { ConfigurationActionTypes } from '../context/configuration-reducer';
import { useLocalizer } from '../hooks';
import { Language } from '../models';
import { setLanguage } from '../services/session.service';

export const Header: FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const langClicked = async (event: React.MouseEvent<HTMLButtonElement>, lang: Language) => {
    event.preventDefault();
    await setLanguage(lang);
    dispatch({
      type: ConfigurationActionTypes.SetLanguage,
      payload: lang,
    });

    location.reload();
  };

  const menuItems: Array<any> = [
    {
      icon: Icons.Change,
      href: '/choose-account',
      text: state.configuration.header.chooseAccountLabel,
    },
    ...(state.configuration.header.menuItems ?? []),
    {
      href: '/sign-out',
      text: state.configuration.header.logOutLabel,
    },
  ];

  const { globalL } = useLocalizer('global');

  const navItems: Array<any> = state.configuration.navItems.map((ni) => {
    return {
      text: ni.name,
      href: ni.url,
      active: ni.name === globalL('pageTitle'),
    };
  });

  const languages: LanguageLib[] | undefined = state.configuration.header.languages?.map((l, i) => {
    return {
      code: ['nb', 'nn', 'en'][i],
      name: l.text!,
      isSelected: state.configuration.language === l.value,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        langClicked(event, l.value as Language);
      },
    };
  });

  return (
    <BaseHeader
      name={state.user.currentUser.displayName}
      companyName={state.user.currentUser.companyName}
      loggedInAsLabel={state.configuration.header.loggedInAsLabel}
      menuItems={menuItems}
      navItems={navItems}
      logoAriaLabel={'Mypage Banking'}
      logoLink={state.configuration?.header?.logoUrl ?? ''}
      languagesLabel={state.configuration?.header?.languageLabel}
      languages={languages}
      hotJarClass="data-hj-suppress"
      closeDropdownOnClick={true}
      as="div"
    ></BaseHeader>
  );
};
