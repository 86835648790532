import { useEffect, useState } from 'react';

export enum EnvironmentType {
  Dev = 'dev',
  Prod = 'prod',
  Test = 'test',
  Undetected = 'undetected',
}

export const useEnvironment = () => {
  const [caseHelpBase, setCaseHelpBase] = useState<string>('');
  const [environment, setEnvironment] = useState<EnvironmentType>(EnvironmentType.Undetected);
  const [environmentInitialised, setEnvironmentInitialised] = useState(false);
  const [href, setHref] = useState(window.location.href);
  const [startPageBase, setStartPageBase] = useState<string>('');

  useEffect(() => {
    const updateHref = () => {
      setHref(window.location.href);
    };
    window.addEventListener('popstate', updateHref);

    return () => {
      window.removeEventListener('popstate', updateHref);
    };
  }, []);

  const getCaseHelpBaseUrl = () => {
    return caseHelpBase;
  };

  const getStartPageBaseUrl = () => {
    return startPageBase;
  };

  const isProd = () => {
    return environmentInitialised && environment === EnvironmentType.Prod;
  };

  useEffect(() => {
    if (href) {
      const match: RegExpMatchArray | null = href.match(/.*digibank-mypage(.*?)\..*?(\.local|\.no).*/);
      if (match !== null && match[2] !== undefined) {
        if (match[2] === '.no') {
          if (match[1] === '-dev') setEnvironment(EnvironmentType.Dev);
          else if (match[1] === '-test') setEnvironment(EnvironmentType.Test);
          else if (match[1] === null) setEnvironment(EnvironmentType.Prod);
        } else setEnvironment(EnvironmentType.Dev);
      } else {
        const match: RegExpMatchArray | null = href.match(/.*?kunde-digibank-mypage-(dev||test||prod)-web-internal-ad-slot.*?\.net.*/);
        if (match !== null && match[1] !== undefined) {
          setEnvironment(match[1] as EnvironmentType);
        } else {
          const match: RegExpMatchArray | null = href.match(/.*?nettbank[-]*(.*?)\.innovasjonnorge\.no.*/);

          if (match !== null && match[1] !== undefined) {
            if (match[1] === '') {
              setEnvironment(EnvironmentType.Prod);
            } else {
              setEnvironment(match[1] as EnvironmentType);
            }
          } else {
            console.error('Could not detect the environment.');
          }
        }
      }
    }
  }, [href]);

  useEffect(() => {
    console.log('Environment detected: ' + environment);
  }, [environment]);

  useEffect(() => {
    if (environment !== EnvironmentType.Undetected) {
      interface environments {
        [key: string]: string;
      }
      const caseHelpBase: environments = {
        [EnvironmentType.Dev]: 'https://prosjekthjelpen-dev.innovasjonnorge.no/',
        [EnvironmentType.Test]: 'https://prosjekthjelpen-test.innovasjonnorge.no/',
        [EnvironmentType.Prod]: 'https://prosjekthjelpen.innovasjonnorge.no/',
      };
      setCaseHelpBase(caseHelpBase[environment]);
      const startPageBase: environments = {
        [EnvironmentType.Dev]: 'https://start-dev.innovasjonnorge.no/',
        [EnvironmentType.Test]: 'https://start-test.innovasjonnorge.no/',
        [EnvironmentType.Prod]: 'https://start.innovasjonnorge.no/',
      };
      setStartPageBase(startPageBase[environment]);
    }
  }, [environment]);

  useEffect(() => {
    if (caseHelpBase !== '') setEnvironmentInitialised(true);
  }, [caseHelpBase]);

  return {
    environmentInitialised,
    getCaseHelpBaseUrl,
    getStartPageBaseUrl,
    isProd,
  };
};
